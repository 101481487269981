.heading {
  font-size: 56px;
  margin: 0;
}
@media (max-width: 756px) {
  .heading {
    font-size: 42px;
  }
}

@media (max-width: 530px) {
  .heading {
    font-size: 28px;
  }
}

@media (max-width: 350px) {
  .heading {
    font-size: 25px;
  }
}
