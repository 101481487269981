.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: white;
  /* height: 184px; */
  width: 379px;
  border-radius: 38px;

  padding: 28px 42px;
}

.container .name {
  font-weight: 700;
}

@media (max-width: 756px) {
  .container {
    height: fit-content;
    width: 330px;
  }
}

@media (max-width: 500px) {
  .container {
    height: fit-content;
    width: 200px;
  }
}

.name {
  font-size: 26px;
  margin: 0 0 18px 0;
}

@media (max-width: 756px) {
  .name {
    font-size: 22px;
    margin: 0 0 20px 0;
  }
}

@media (max-width: 500px) {
  .name {
    font-size: 20px;
    margin: 0 0 15px 0;
  }
}

.description {
  margin: 0;
  font-size: 18px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* number of lines to show */
  -webkit-box-orient: vertical;
}

@media (max-width: 756px) {
  .description {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .description {
    font-size: 14px;
  }
}
