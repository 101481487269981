.container {
  padding: 45px 0;
  color: #303030;
}

.servicesList {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  gap: 44px;

  transition: all 300ms ease-in-out;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #cecece;
}
