.container {
  max-width: 1270px;
  margin: 0 auto;

  background: rgba(255, 255, 255, 0.5);
  border-radius: 12px;

  padding: 10px 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
}
@media (max-width: 1350px) {
  .container {
    max-width: 970px;
  }
}

@media (max-width: 1100px) {
  .container {
    max-width: 80%;
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.img {
  max-width: 100px;
}

@media (max-width: 1350px) {
  .img {
    max-width: 67px;
  }
}
@media (max-width: 800px) {
  .img {
    max-width: 45px;
  }
}

.slogan {
  font-size: 32px;
  font-weight: bold;

  color: white;
}

.slogan > span {
  color: var(--primary-brand-color);
}

@media (max-width: 1350px) {
  .slogan {
    font-size: 22px;
  }
}

@media (max-width: 1100px) {
  .slogan {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .slogan {
    font-size: 11px;
  }
}

.contacts {
  display: flex;
  gap: 20px;
  align-items: center;
}

.numbers {
  color: #5e5e5e;
}

@media (max-width: 1100px) {
  .numbers {
    display: none;
  }
}
