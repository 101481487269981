.container {
  height: 654px;

  background: #a4a4a3 url("../../img/banner-min.webp") no-repeat center;
  background-size: cover;
}

@media (max-width: 500px) {
  .container {
    height: 554px;
  }
}

.heading {
  color: white;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;

  max-width: 780px;
  padding: 10px;
  background-color: rgba(191, 191, 191, 0.3);
  border-radius: 14px;

  margin: 0;
}

@media (max-width: 756px) {
  .heading {
    font-size: 44px;
    line-height: 52px;
    max-width: 700px;
  }
}

@media (max-width: 500px) {
  .heading {
    max-width: 300px;
    font-size: 28px;
    line-height: 32px;
  }
}

.wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 28px;

  padding-top: 200px;
}

@media (max-width: 1100px) {
  .wrapper {
    padding-top: 150px;
  }
}

.numbers {
  background-color: rgba(191, 191, 191, 0.8);
  border-radius: 12px;
  padding: 10px;
  width: fit-content;

  color: white;

  font-size: 14px;
}

@media (min-width: 1100px) {
  .numbers {
    display: none;
  }
}
