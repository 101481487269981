.container {
  /* padding: 45px 65px; */
  height: 400px;
  padding-top: 45px;
  padding-bottom: 45px;

  position: relative;

  background-color: var(--primary-brand-color);
  color: #303030;
}

@media (max-width: 756px) {
  .container {
    height: 360px;
  }
}

@media (max-width: 500px) {
  .container {
    height: 320px;
    padding-bottom: 60px;
  }
}

@media (max-width: 400px) {
  .container {
    height: 360px;
    padding-bottom: 60px;
  }
}

.container h2 {
  color: white;
}

@media (max-width: 1100px) {
  .container h2 {
    font-size: 45px;
  }
}

@media (max-width: 756px) {
  .container h2 {
    font-size: 36px;
  }
}

.form {
margin-top: 30px;
}


.formGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;

  & label {
    color: White;
    font-size: 28px;
    font-weight: 500;
  }

  & input {
    border: none;
    outline: none;

    border-radius: 5px;
    height: 30px;
    width: 100%;
    max-width: 600px;
    padding-left: 10px;
  }

}

@media (max-width: 500px) {
  .formGroup label {
    font-size: 22px;
  }
}

.button {
  margin-top: 20px;
  border: none;
  outline: none;

  background-color: white;

  border-radius: 5px;
  height: 30px;
  width: 170px;

  transition: opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

