.wrapper {
  width: 1310px;
  margin: 0 auto;
}

@media (max-width: 1350px) {
  .wrapper {
    max-width: 1010px;
  }
}

@media (max-width: 1100px) {
  .wrapper {
    max-width: 85%;
  }
}

.sliderButtons {
  display: flex;
  gap: 10px;

  & > button {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 1200px) {
  .sliderButtons {
    display: none;
  }
}
