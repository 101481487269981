.button {
  color: white;
  background-color: var(--primary-brand-color);

  border: none;
  outline: none;

  border-radius: 12px;
  padding: 16px 35px;

  width: fit-content;
  font-size: 24px;
}

.button:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media (max-width: 1100px) {
  .button {
    font-size: 16px;
  }
}
