.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: white;
  height: 194px;
  width: 330px;
  border-radius: 38px;

  padding: 28px 42px;

  @media (max-width: 756px) {
    height: fit-content;
    width: 330px;
  }

  @media (max-width: 500px) {
    height: fit-content;
    width: 270px;
  }
}

.container span {
  font-weight: 700;
}

@media (max-width: 756px) {
  .container {
    height: fit-content;
    width: 330px;
  }
}

@media (max-width: 500px) {
  .container {
    height: fit-content;
    width: 270px;
  }
}

.inn {
  font-size: 38px;
  margin: 0 0 12px 0;
  white-space: nowrap;
}
@media (max-width: 756px) {
  .inn {
    font-size: 22px;
    margin: 0 0 20px 0;
  }
}

@media (max-width: 500px) {
  .inn {
    font-size: 20px;
    margin: 0 0 15px 0;
  }
}

.ifns,
.accounts,
.price {
  margin: 0;
}

.ifns,
.accounts {
  margin: 0;
  font-size: 20px;
}
@media (max-width: 756px) {
  .ifns,
  .accounts {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .ifns,
  .accounts {
    font-size: 14px;
  }
}

.price {
  font-size: 28px;
  font-weight: 700;
  color: var(--primary-brand-color);
}
@media (max-width: 500px) {
  .price {
    font-size: 18px;
  }
}
