.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.phone,
.mail {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.phone a,
.mail a {
  display: flex;
  align-items: center;
  gap: 8px;

  text-decoration: none;
  color: #303030;
}

.phone a:hover,
.mail a:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 500px) {
  .phone,
  .mail {
    flex-direction: column;
    gap: 4px;
  }
}
