.container {
  /* padding: 45px 65px; */
  height: 400px;
  padding-top: 45px;
  padding-bottom: 45px;

  position: relative;

  background-color: var(--primary-brand-color);
  color: #303030;
}

@media (max-width: 756px) {
  .container {
    height: 320px;
  }
}

@media (max-width: 500px) {
  .container {
    height: 320px;
    padding-bottom: 60px;
  }
}

.container h2 {
  color: white;
}

@media (max-width: 1100px) {
  .container h2 {
    font-size: 45px;
  }
}

@media (max-width: 756px) {
  .container h2 {
    font-size: 36px;
  }
}

.carusel {
  position: absolute;
  width: calc((100% - ((100% - 1310px) / 2)) - 20px);
  padding-left: calc((100% - 1310px) / 2);
  padding-right: 20px;

  display: flex;
  gap: 50px;
  overflow-x: auto;

  margin-top: 50px;

  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-width: 1350px) {
  .carusel {
    width: calc((100% - ((100% - 1000px) / 2)) - 20px);
    padding-left: calc((100% - 1000px) / 2);
  }
}

@media (max-width: 1100px) {
  .carusel {
    width: calc((100% - ((100% - 85%) / 2)) - 20px);
    padding-left: calc((100% - 85%) / 2);
  }
}

@media (max-width: 756px) {
  .carusel {
    margin-top: 40px;
  }
}

@media (max-width: 500px) {
  .carusel {
    gap: 20px;
  }
}

.carusel > * {
  flex-shrink: 0;
}

.carousel::-webkit-scrollbar {
  display: none;
}
