.container {
  padding: 45px 0;
  background: url("../../img/aboutBG.png") no-repeat center;
  background-size: cover;
  background-position: 0% 20%;
  color: #303030;
}

.container > div {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1030px) {
  .container > div {
    flex-direction: column;
    gap: 25px;
  }
}

.title {
  font-weight: 600;
  font-size: 21px;

  margin-bottom: 50px;
}

@media (max-width: 1100px) {
  .title {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.description {
  white-space: pre-wrap;
  max-width: 700px;
}

@media (max-width: 1100px) {
  .description {
    font-size: 14px;
    margin-bottom: 10px;

    max-width: 500px;
  }
}
