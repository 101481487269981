.serviceName {
  font-size: 30px;
  font-weight: 700;
}
@media (max-width: 756px) {
  .serviceName {
    font-size: 26px;
  }
}

@media (max-width: 500px) {
  .serviceName {
    font-size: 20px;
  }
}

.row {
  display: flex;
  align-items: center;

  justify-content: space-between;
}

.row svg {
  transition: transform 300ms ease-in-out;
  min-width: 74px;
}

.row svg[data-hidden="true"] {
  transform: rotate(45deg);
}

@media (max-width: 756px) {
  .row svg {
    min-width: 56px;
    min-height: 56px;
  }
}

@media (max-width: 590px) {
  .row svg {
    min-width: 44px;
    min-height: 44px;
  }
}

.list {
  margin-left: -20px;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

  &[data-hidden="true"] {
    opacity: 0;
    transform: translateY(-10px);
    max-height: 0;
    overflow: hidden;
  }

  li {
    list-style-image: url("../../img/circle.svg");
    padding-inline-start: 2ch;
    margin-bottom: 28px;

    @media (max-width: 500px) {
      font-size: 14px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.list[data-hidden="true"] {
  opacity: 0;
  transform: translateY(-10px);
  max-height: 0;
  overflow: hidden;
}

.list li {
  list-style-image: url("../../img/circle.svg");
  padding-inline-start: 2ch;
  margin-bottom: 28px;
}

@media (max-width: 500px) {
  .list li {
    font-size: 14px;
  }
}

.list li:last-child {
  margin-bottom: 0px;
}
