.container {
  max-width: 1270px;
  margin: 0 auto;

  background: rgba(255, 255, 255, 0.5);
  border-radius: 12px;

  padding: 40px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1350px) {
  .container {
    max-width: 970px;
  }
}

@media (max-width: 1100px) {
  .container {
    max-width: 80%;
  }
}

@media (max-width: 950px) {
  .container {
    flex-direction: column;
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 16px;
}

@media (max-width: 950px) {
  .logo {
    width: 100%;
  }

  .logo button {
    width: 100%;
    margin-bottom: 25px;
  }
}

.contacts {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media (max-width: 950px) {
  .contacts {
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 650px) {
  .contacts {
    flex-direction: column;
    gap: 40px;
  }
}

.numbers {
  color: #5e5e5e;
}
